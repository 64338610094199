const abiFields = [
  'storename',
  'timezone',
  'chargetotal',
  'txntype',
  'responseSuccessURL',
  'responseFailURL',
  'currency',
  'txndatetime',
  'hashExtended',
  'checkoutoption',
  'hash_algorithm',
  'paymentMethod',
  'invoicenumber',
  'oid',
  'phone',
  'email',
  'comments'
]

async function getHash(form) {  
  const payload = {};
  for(const field of form.querySelectorAll('input, select, textarea')) {
	  payload[field.name] = field.value
  }
  const req = await fetch(form.dataset.hashAction, {
    method: "post",
    body: JSON.stringify(payload),
  });
  const res = await req.json();
  form.querySelector("[name='hashExtended']").value = res.hash
  form.querySelector("[name='oid']").value = res.oid
  form.querySelector("[name='comments']").value = res.comments
  form.action = form.dataset.action
} 

function clearHashAndOID(form) {
  form.querySelector("[name='hashExtended']").value = ''
  form.querySelector("[name='oid']").value = ''
}

async function submitForm(form, e) {
  const hashField = form.querySelector("[name='hashExtended']");
  if (hashField.value === "") {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    await getHash(form);
    for (const field of form.querySelectorAll('input, select, textarea')) {
      if(!abiFields.includes(field.name)) {
        field.disabled = true;
      }
    }
    form.submit();
  }
}

async function submitWithPaymentMethod(form, method) {
  const input = document.createElement('input')
  input.type = 'hidden'
  input.name = 'paymentMethod'
  input.value= method
  form.appendChild(input)
  await submitForm(form)
}

document.addEventListener("DOMContentLoaded", (e) => {
  const form = document.getElementById("payment_form");
  if (!form) {
    console.log("no payment form present!");
    return;
  }
  clearHashAndOID(form);
  form.addEventListener("submit", async (e) => {
    await submitForm(form, e)
  });
  form.querySelector('#apple-pay').addEventListener('click', async (e) => {
    e.preventDefault()
    e.stopImmediatePropagation()
    await submitWithPaymentMethod(form, 'applePay')
  })
  form.querySelector('#google-pay').addEventListener('click', async (e) => {
    e.preventDefault()
    e.stopImmediatePropagation()
    await submitWithPaymentMethod(form, 'googlePay')
  })
});
